import React, {Fragment, useState} from 'react';

import {Link, Redirect} from "react-router-dom";

import {Button, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from 'reactstrap';

import {faAngleDown,} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Switch from "react-switch";
import city3 from '../../assets/utils/images/dropdown-header/city3.jpg';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {mapStringToColor} from "../../utils/mappers/mapStringToColor";
import {isCleany, setCleany, setZumzi} from "../../utils/app";
import {useDispatch, useSelector} from "react-redux";
import {logoutAction} from "../../redux/actions";

const UserBox = (props) => {
    const [active, setActive] = useState(false)
    const [checked, setChecked] = useState(!isCleany())

    const authSession = useSelector(state => state.authSession)
    const dispatch = useDispatch()

    const changeApp = (checkedAux) => {
        if (checked) {
            setZumzi()
        } else {
            setCleany()
        }
        setChecked(checkedAux)
        window.location.reload()
    }

    const logout  = () => {
        dispatch(logoutAction())
    }

    return (
      authSession ? <Fragment>
          <div className="widget-content-wrapper">
              <div className="widget-content-left header-dropdown-menu">
                  <UncontrolledButtonDropdown>
                      <DropdownToggle color="link" className="p-0">
                          <img width={42} className="rounded-circle" src={authSession.image} alt=""/>
                          <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
                      </DropdownToggle>
                      <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                          <div className="dropdown-menu-header">
                              <div className="dropdown-menu-header-inner bg-info">
                                  <div className="menu-header-image opacity-2"
                                       style={{
                                           backgroundImage: 'url(' + city3 + ')'
                                       }}
                                  />
                                  <div className="menu-header-content text-left">
                                      <div className="widget-content p-0">
                                          <div className="widget-content-wrapper">
                                              <div className="widget-content-left mr-3">
                                                  <img width={42} className="rounded-circle" src={authSession.image}
                                                       alt=""/>
                                              </div>
                                              <div className="widget-content-left">
                                                  <div className="widget-heading">
                                                      {authSession.name}
                                                  </div>
                                              </div>
                                              <div className="widget-content-right mr-2">
                                                  <Link to="/">
                                                      <Button className="btn-pill btn-shadow btn-shine"
                                                              color="focus" onClick={logout}>
                                                          Logout
                                                      </Button>
                                                  </Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="d-flex" style={{'justifyContent': 'center'}}>
                                            <span className="cleany-color ml-2 mr-2 font-weight-bold" style={{'marginTop': '0.75rem'}}>
                                                Cleany
                                            </span>
                                  <Switch
                                    checked={checked}
                                    onChange={changeApp}
                                    onColor={mapStringToColor('zumzi')}
                                    onHandleColor={mapStringToColor('zumzi')}
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="mt-2 mr-2 mb-2"
                                    id="material-switch"
                                  />
                                  <span className="zumzi-color ml-2 font-weight-bold" style={{'marginTop': '0.75rem'}}>
                                                Zumzi
                                            </span>
                              </div>
                          </div>
                      </DropdownMenu>
                  </UncontrolledButtonDropdown>

              </div>
              <div className="widget-content-left  ml-3 header-user-info">
                  <div className="widget-heading">
                      {authSession.name}
                  </div>
              </div>
          </div>

      </Fragment> : <Redirect to="/" />
    )
}

export default UserBox;