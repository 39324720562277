import { Route, Switch } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import LoaderComponent from "../components/Common/Loader";
import { ToastContainer } from "react-toastify";
import NotFound from "../components/NotFound";

const Dashboard = lazy(() => import("../containers/Dashboard"));
const DashboardSimple = lazy(() => import("../containers/DashboardSimple"));

const Reviews = lazy(() => import("../containers/Reviews"));
const Login = lazy(() => import("../containers/Login"));
const Users = lazy(() => import("../containers/Users"));
const Orders = lazy(() => import("../containers/Orders"));
const OrderPage = lazy(() => import("../containers/OrderPage"));
const Bookings = lazy(() => import("../containers/Bookings"));
const BookingPage = lazy(() => import("../containers/BookingPage"));
const LogoutPage = lazy(() => import("../containers/Logout"));
const BookingRequests = lazy(() => import("../containers/BookingRequests"));

const AgentsStats = lazy(() => import("../containers/AgentsRevenues"));
const AddOrder = lazy(() => import("../containers/AddOrder"));
const AddOrderZumzi = lazy(() => import("../containers/AddOrderZumzi"));

const Agents = lazy(() => import("../containers/Agents"));
const Neighbourhood = lazy(() => import("../containers/Neighbourhoods"));
const Map = lazy(() => import("../containers/Map"));
const MapForPossibleClients = lazy(() =>
    import("../containers/MapForPossibleClients")
);
const Subscriptions = lazy(() => import("../containers/Subscriptions"));
const Subscription = lazy(() => import("../containers/Subscription"));
const BlockedDates = lazy(() => import("../containers/BlockedDates"));
const Offers = lazy(() => import("../containers/Offers"));
const OfferPage = lazy(() => import("../containers/OfferPage"));
const Notifications = lazy(() => import("../containers/Notifications"));
const OfferAdd = lazy(() => import("../containers/OfferAdd"));
const Refunds = lazy(() => import("../containers/Refunds"));
const AddPublicAddress = lazy(() => import("../containers/AddPublicAddress"));
const AddAgentRevenue = lazy(() => import("../containers/AddAgentRevenue"));
const AgentRevenues = lazy(() => import("../containers/AgentRevenues"));
const PublicAddresses = lazy(() => import("../containers/PublicAddresses"));
const Tickets = lazy(() => import("../containers/Tickets"));
const ProductForms = lazy(() => import("../containers/ProductForms"));
const ProductForm = lazy(() => import("../containers/ProductForm"));

const AddPushNotification = lazy(() => import("../containers/AddPushNotification"));

const BillingData = lazy(() => import("../containers/Profile/BillingData"));
const User = lazy(() => import("../containers/Profile/User"));
const Schedule = lazy(() => import("../containers/Profile/Schedule"));
const Location = lazy(() => import("../containers/Profile/Location"));
const Services = lazy(() => import("../containers/Profile/Services"));
const PaymentModel = lazy(() => import("../containers/Profile/PaymentModel"));

const ProductFormRequests = lazy(() => import("../containers/ProductFormRequests"));
const ProductFormRequest = lazy(() => import("../containers/ProductFormRequestPage"));
const ProductFormRequestAccept = lazy(() => import("../components/ProductFormRequest/ProductFormRequestAccept"));

const Merchants = lazy(() => import("../containers/Merchants"));
const MerchantDetails = lazy(() => import("../containers/MerchantDetails"));

const Products = lazy(() => import("../containers/Products"));
const ProductOptionTree = lazy(() => import("../containers/ProductOptionTree"));
const ProductDetails = lazy(() => import("../containers/ProductDetails"));

const Routes = () => {
    return (
        <Fragment>
            <Suspense fallback={<LoaderComponent />}>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/dashboard-simple" component={DashboardSimple} />
                    <Route exact path="/refunds" component={Refunds} />
                    <Route exact path="/notifications" component={Notifications} />
                    <Route exact path="/stats-agents" component={AgentsStats} />
                    <Route exact path="/orders-add" component={AddOrder} />
                    <Route exact path="/orders-add-zumzi" component={AddOrderZumzi} />

                    <Route exact path="/offers/:offerId" component={OfferPage} />
                    <Route exact path="/offer-add" component={OfferAdd} />
                    <Route exact path="/order/:orderId" component={OrderPage} />
                    <Route exact path="/reviews" component={Reviews} />
                    <Route exact path="/booking/:bookingId" component={BookingPage} />
                    <Route exact path="/bookings" component={Bookings}/>
                    <Route exact path="/orders" component={Orders} />
                    <Route exact path="/users" component={Users} />
                    <Route exact path="/agents" component={Agents} />

                    <Route exact path="/merchants" component={Merchants} />
                    <Route exact path="/merchants/details" component={MerchantDetails} />
                    <Route exact path="/products" component={Products} />
                    <Route exact path="/product-option-tree" component={ProductOptionTree} />
                    <Route exact path="/products/details" component={ProductDetails} />

                    <Route exact path="/booking-requests" component={BookingRequests} />
                    <Route exact path="/product-form-requests" component={ProductFormRequests} />
                    <Route exact path="/product-form-request/:productFormRequestId" component={ProductFormRequest} />
                    <Route exact path="/product-form-request/:productFormRequestId/accept" component={ProductFormRequestAccept} />


                    <Route exact path="/logout" component={LogoutPage} />
                    <Route exact path="/neighbourhoods" component={Neighbourhood} />
                    <Route exact path="/map" component={Map} />
                    <Route exact path="/possible-clients" component={MapForPossibleClients}/>
                    <Route exact path="/subscriptions" component={Subscriptions} />
                    <Route exact path="/offers" component={Offers} />
                    <Route exact path="/subscription/:subscriptionId" component={Subscription}/>
                    <Route exact path="/blocked-dates" component={BlockedDates} />
                    <Route exact path="/add-public-address" component={AddPublicAddress} />
                    <Route exact path="/public-addresses" component={PublicAddresses} />
                    <Route exact path="/add-agent-revenue/:agentId" component={AddAgentRevenue} />
                    <Route exact path="/agent-revenues/:agentId" component={AgentRevenues} />
                    <Route exact path="/tickets" component={Tickets} />
                    <Route exact path="/product-forms" component={ProductForms} />
                    <Route exact path="/product-form/:productFormId" component={ProductForm} />
                    <Route exact path="/add-push-notifications" component={AddPushNotification} />

                    <Route exact path="/user/:userId/personal-data" component={User}/>
                    <Route exact path="/agent/:agentId/services" component={Services}/>
                    <Route exact path="/agent/:agentId/invoice" render={(routeProps) => <BillingData {...routeProps} />}/>
                    <Route exact path="/agent/:agentId/schedule" component={Schedule}/>
                    <Route exact path="/agent/:agentId/location" component={Location}/>
                    <Route exact path="/agent/:agentId/payment-model" component={PaymentModel}/>

                    <Route exact path='*' component={NotFound} />
                </Switch>
            </Suspense>
            <ToastContainer />
        </Fragment>
    );
};

export default Routes;
