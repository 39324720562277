import {batch} from 'react-redux'

import {GET_PRODUCT_FORM_REQUEST_SUCCESS, HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import axiosRequest from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";
import {getProductFormAction} from "./productForm.actions";

export const getProductFormRequestByIdAction = (productFormRequestId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/product_form_requests/${productFormRequestId}`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PRODUCT_FORM_REQUEST_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const acceptAgentProductFormRequestAction = (productFormRequestId, productOptionId, totalValue, localDateTimes = null) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	const body = {productOption: {id: productOptionId}}
	if (totalValue) {
		body.totalValue = totalValue
	}
	if (localDateTimes) {
		body.localDateTimes = localDateTimes
	}

	try {
		const resp = await axiosRequest.put(`/product_form_requests/${productFormRequestId}/agent/set_offer`, body)
		const payload = resp.data

		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: GET_PRODUCT_FORM_REQUEST_SUCCESS, payload})
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Propunere trimisa', route: '/product-form-request/' + productFormRequestId}})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const rejectAgentProductFormRequestAction = (productFormRequest, reason) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.put(`/product_form_requests/${productFormRequest.id}/agent/cancel`, {reason})

		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Cerere respinsa'}})
			window.location.reload()
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const putMoreDetailsProductFormRequestAction = (productFormRequest, moreDetails) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.put(`/product_form_requests/${productFormRequest.id}/agent/more_details`, {moreDetails})

		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Detalii trimise'}})
			window.location.reload()
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}