import {loadFromLocalStorage, saveToLocalStorage} from "./storage";

const APP_NAME = 'app_name'
const ZUMZI = 'zumzi';
const CLEANY = 'cleany';
const ONE_YEAR = 60 * 24 * 365;

export const APP_GROUP_CLEANY = 1;
export const APP_GROUP_ZUMZI = 2;

export const isCleany = () => {
    return loadFromLocalStorage(APP_NAME) === CLEANY;
}

export const isZumzi = () => {
    return loadFromLocalStorage(APP_NAME) === ZUMZI;;
}

export const setCleany = () => {
    saveToLocalStorage(APP_NAME, CLEANY, ONE_YEAR)
}

export const setZumzi = () => {
    saveToLocalStorage(APP_NAME, ZUMZI, ONE_YEAR)
}

export const getAppGroup = () => {
    return loadFromLocalStorage(APP_NAME) === CLEANY ? APP_GROUP_CLEANY : APP_GROUP_ZUMZI;
}
