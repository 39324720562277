export const mapStringToColor = (string) => {
    switch (string) {
        case 'red':
            return '#e91a50';
        case 'green':
            return '#56CC9D';
        case 'blue':
            return '#007bff';
        case 'yellow':
            return '#FFCE67';
        case 'zumzi':
            return '#0367BF';
        case 'orange':
            return '#EE7814';
        default:
    }
}