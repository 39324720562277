import React, {Component} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import myConfirm from "reactstrap-confirm";

class PageTitle extends Component {
    myConfirmFunction = async (callback, params) => {
        const options = {
            title: "Stergere",
            message: "Sigur vrei sa stergi aceste date?",
            confirmText: "Confirm",
        };
        let result = await myConfirm(options);

        if (result) {
            if (params) {
                callback(...params);
            } else {
                callback();
            }
        }
    }

    render() {
        let {
            enablePageTitleIcon,
            enablePageTitleSubheading,

            heading,
            icon,
            subheading,
            // pageName,
            deleteItem,
            deleteItemFunction,
            deleteItemParams,

        } = this.props;

        return (

            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">
                        <div
                            className={cx("page-title-icon", {'d-none': !enablePageTitleIcon})}>
                            <i className={icon}/>
                        </div>
                        <div>
                            {heading}
                            <div
                                className={cx("page-title-subheading", {'d-none': !enablePageTitleSubheading})}>
                                {subheading}
                            </div>
                        </div>
                    </div>
                    <div className="page-title-actions">
                        {
                            deleteItem
                            ? <div className="red-color font-size-md" style={{cursor: 'pointer'}} onClick={() => this.myConfirmFunction(deleteItemFunction, deleteItemParams)}>
                                    <i className="dropdown-icon lnr-cross-circle"> </i>
                                    <span>Sterge</span>
                                </div>
                            : null
                        }
                        {/*<Breadcrumb>*/}
                        {/*    <BreadcrumbItem><a href="/" onClick={(e)=>e.preventDefault()}>*/}
                        {/*        <FontAwesomeIcon icon={faHome}/></a>*/}
                        {/*    </BreadcrumbItem>*/}
                        {/*    <BreadcrumbItem>*/}
                        {/*        <a href={window.location.pathname} onClick={(e)=>e.preventDefault()}>{pageName}</a>*/}
                        {/*    </BreadcrumbItem>*/}

                        {/*</Breadcrumb>*/}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);