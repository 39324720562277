import { GET_PRODUCT_FORM_REQUEST_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = {}

export const productFormRequest = (state = initialState, action) => {
	switch (action.type) {
		case GET_PRODUCT_FORM_REQUEST_SUCCESS:
			return action.payload
		case LOGOUT_RESET:
			return initialState
		default:
			return state
	}
}