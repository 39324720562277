import ThemeOptions from './ThemeOptions';
import {spinner} from "./spinner";
import {productFormRequests} from "./productFormRequests.reducer";
import {productFormRequest} from "./productFormRequest.reducer";
import {productForm} from "./productForm.reducer";
import {modalPopup} from "./modalPopup.reducer";
import {authSession} from "./login";
import {alertModal} from "./alertModal.reducer";
import {productOptionTreeNode} from "./productOptionTreeNode.reducer";

export default {
	spinner,
	authSession,
	ThemeOptions,
	productFormRequest,
	productFormRequests,
	productForm,
	modalPopup,
	alertModal,
	productOptionTreeNode
};