import {LOGOUT_RESET, SET_MODAL_POPUP} from '../constants'
import {modalPopupInitialState} from "../../utils/model/modalPopup";

const initialState = modalPopupInitialState

export const modalPopup = (state = initialState, action) => {
	switch (action.type) {
		case SET_MODAL_POPUP:
			return action.payload
		case LOGOUT_RESET:
			return initialState
		default:
			return state
	}
}