import React from 'react';
import {useSelector} from "react-redux";
import {Bounce, toast, ToastContainer} from "react-toastify";
import '../../assets/css/components/react_toastify/react_toastify.scss';
import {Redirect} from "react-router-dom";


export const ModalAlert = (props) => {
	const {isVisible, type = 'error', message, route} = useSelector(state => state.alertModal)

	if (!isVisible) {
		return <></>
	}
	toast(message, {
		transition: Bounce,
		closeButton: true,
		autoClose: 3000,
		position: "bottom-right",
		type: type
	})

	if (route) {
		const parsedRoute = route.indexOf("?") !== -1 ? route.substring(0, route.indexOf("?")) : route
		if (window.location.pathname === parsedRoute) {
			setTimeout(() => {
				window.location.reload()
			}, 1500)
		}

		return <>
			<ToastContainer/>
			<Redirect to={route}/>
		</>
	}


	return <ToastContainer/>
}