import React, {Fragment} from 'react';

import {CSSTransitionGroup} from "react-transition-group";

import PageTitle from "./Common/PageTitle";

const NotFound = ({match}) => (
    <Fragment>
        <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <PageTitle
                pageName="404"
                heading="Eroare 404"
                subheading="Pagina solicitata nu a putut fi gasita."
                icon="pe-7s-help1 icon-gradient bg-strong-bliss"
            />
        </CSSTransitionGroup>
    </Fragment>
);

export default NotFound;