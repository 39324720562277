import axios from "axios";
import {
    AUTHORIZATION_MECHANISM,
    HEADER_AUTHORIZATION,
    HEADER_X_API_KEY, HEADER_X_APP_ID,
    X_API_KEY, X_APP_ID
} from "../utils/constants/constants";
import {loadFromLocalStorage} from "../utils/storage";

const options = {
    baseURL: window.REACT_APP_API_BASE_PATH,
    headers: {
        [HEADER_X_API_KEY]: X_API_KEY,
        [HEADER_X_APP_ID]: X_APP_ID,
    }
}

const axiosRequest = axios.create(options);

axiosRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response.data.code);
    if (
      error.response.data.code === 401 &&
      error.response.data.message === "Invalid JWT Token"
    ) {
      window.location.replace("/logout");
    }

    if (
      error.response.data.code === 401 &&
      error.response.data.message === "Expired JWT Token"
    ) {
      window.location.replace("/logout");
    }
    if(error.response.data.code === 403){      //access denied
      window.location.replace("/logout");
    }

    return Promise.reject(error);
  }
);

axiosRequest.interceptors.request.use(
    (request) => {
        if (request.url.includes("itemsPerPage")) {
            request.url = request.url.replace("itemsPerPage", "pagination=true&itemsPerPage")
        }

        const authSession = loadFromLocalStorage('authSession');
        if (authSession?.token) {
            request.headers[HEADER_AUTHORIZATION] = `${AUTHORIZATION_MECHANISM} ${authSession.token}`
        }
      return request
    }
)

export default axiosRequest;
