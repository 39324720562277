/* REQUEST RELATED CONST */
import {isCleany} from "../app";

export const HEADER_X_API_KEY = "x-apikey";
export const HEADER_X_APP_ID = "x-appid";
export const HEADER_AUTHORIZATION = "Authorization";
export const HEADER_CONTENT_TYPE = 'Content-Type';
export const HEADER_CONTENT_TYPE_FORM_DATA = 'multipart/form-redux';
export const HEADER_SKIP_APP_FILTERING = 'x-skip-app-filtering'

const CLEANY_APP_ID = "bf43be5d-d2f7-11ea-8177-0242ac120003";
const CLEANY_API_KEY = "bWMooCGhSkOFE813D2ikO55NEmktMW9JLKdVLHu4NenXMBTUc09UhTQkNNEpsy91lKmfcwYsvoxYh6i00wjMQ";

const ZUMZI_APP_ID = "36671edb-3d04-410c-8008-0fb985a5382c";
const ZUMZI_API_KEY = "VEtWZDEyV2RkMjFlMU5yUm5OT1Z6RkpVbTAxTm1SVlNrOU9WcEVUalJaVkZFeVdHSkVRblZhU0Z";

export const X_APP_ID = isCleany() ? CLEANY_APP_ID : ZUMZI_APP_ID;
export const X_API_KEY = isCleany() ? CLEANY_API_KEY : ZUMZI_API_KEY;
export const AUTHORIZATION_MECHANISM = "Bearer";


/* APP RELATED CONST */
export const BOOKING_STATUS_DEFAULT = 0;
export const BOOKING_STATUS_DELETED = 1;
export const BOOKING_STATUS_CONFIRMED = 2;
export const BOOKING_STATUS_CANCELLED = 3;
export const BOOKING_STATUS_WAITING_FOR_CONFIRMATION = 4;

export const BOOKING_REQUEST_STATUS_PENDING = 1;
export const BOOKING_REQUEST_STATUS_CONFIRMED = 2;
export const BOOKING_REQUEST_STATUS_REJECTED = 3;
export const BOOKING_REQUEST_STATUS_EXPIRED = 4;
export const BOOKING_REQUEST_STATUS_AUTO_REJECTED = 5;

export const LISTING_TYPE_CUSTOM = 3;

export const PROPERTY_TYPE_APARTMENT = "apartment";
export const PROPERTY_TYPE_HOUSE = 'house';
export const PROPERTY_TYPE_OFFICE = 'office';
export const PROPERTY_TYPE_AIRBNB = 'airbnb';
export const PROPERTY_TYPE_COMMERCIAL = 'commercial';
export const PROPERTY_TYPE_MEDICAL = 'medical';
export const PROPERTY_TYPE_INDUSTRIAL = 'industrial';
export const PROPERTY_TYPE_INSTITUTION = 'institution';
export const PROPERTY_TYPE_BLOCK = 'block';

export const ALGOLIA_APP_ID = "plYX87GLJS1U";
export const ALGOLIA_API_KEY = "b8598abf35d12d28a7f0cd01566500a4";

export const BILLING_DATA_TYPE_PERSON = 0;
export const BILLING_DATA_TYPE_COMPANY = 1;

export const ORDER_STATUS_NEW = 0;
export const ORDER_STATUS_PENDING = 1;
export const ORDER_STATUS_PROCESSING = 2;
export const ORDER_STATUS_PAID = 3;
export const ORDER_STATUS_PARTIAL = 4;
export const ORDER_STATUS_OVERPAID = 5;
export const ORDER_STATUS_CANCELED = 6;
export const ORDER_STATUS_FAILED = 7;
export const ORDER_STATUS_REFUND = 8;



export const SUBSCRIPTION_STATUS_ACTIVE = 1;
export const SUBSCRIPTION_STATUS_CONFIRMED = 2;
export const SUBSCRIPTION_STATUS_CANCELLED = 3;
export const SUBSCRIPTION_STATUS_TO_CANCEL = 4;
export const SUBSCRIPTION_STATUS_FROZEN = 5;

export const ORDER_TYPE_NORMAL = 1;
export const ORDER_TYPE_FROM_RECURRENCE = 2;
export const ORDER_TYPE_CUSTOM = 3;

export const CURRENT_INTERVAL_TYPE = 1;
export const PREVIOUS_INTERVAL_TYPE = 2;
export const CURRENT_MONTH_TYPE = 3;

export const GOOGLE_MAPS_API_KEY = 'AIzaSyB5yUa8yi7aD7QvdlBexLLpCVy_FrT3Hio';

export const AGENT_REVENUE_TYPE_BOOKING = 1;
export const AGENT_REVENUE_TYPE_REVIEW = 2;
export const AGENT_REVENUE_TYPE_EXTRA_WORK = 3;
export const AGENT_REVENUE_TYPE_TARGET_HOURS = 4;
export const AGENT_REVENUE_TYPE_CUSTOM = 5;

export const ALLOCATION_TYPE_CITY = 1;
export const ALLOCATION_TYPE_NEIGHBOURHOOD = 2;

export const VALUE_TYPE_NUMBER = 1;
export const VALUE_TYPE_PERCENTAGE = 2;

export const DEFAULT_SELECT_TEXT = 'Alege optiune';

export const INTEGER = 'int';

export const TYPE_IMAGE = 'image';
export const TYPE_VIDEO = 'video';
export const TYPE_AUDIO = 'audio';