import { SHOW_ALERT_MODAL, HIDE_ALERT_MODAL, LOGOUT_RESET } from '../constants'

const initialState = {
    isVisible: false,
    type: 'error',
    message: '',
    route: null,
    params: {}
}

export const alertModal = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_ALERT_MODAL:
            return { ...state, ...action.payload, isVisible: true }
        case HIDE_ALERT_MODAL:
            return { ...initialState }
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}