export function saveToLocalStorage(key, data, expirationMinutes = 60) {
    try {
        let expiration_ms = expirationMinutes * 60 * 1000;
        let record = {
            value: JSON.stringify(data),
            timestamp: new Date().getTime() + expiration_ms
        };

        let serializedRecord = JSON.stringify(record);
        localStorage.setItem(key, serializedRecord);

        return data;
    } catch (err) {
        console.log("Could not save to locale storage. Check your browser settings!");
        return null;
    }
}

export function loadFromLocalStorage(key) {
    try {
        const serializedRecord = localStorage.getItem(key);
        if (serializedRecord === null) {
            return undefined;
        } else {
            let record = JSON.parse(serializedRecord);
            let res = (new Date().getTime() < record.timestamp && JSON.parse(record.value));
            if (res === {} || res === undefined || res === false) {
                localStorage.removeItem(key);
                res = undefined;
            }
            return res;
        }
    } catch(err) {
        console.log("Error loading state from local storage. Please check your browser settings !");
        return undefined;
    }
}