import React, {Fragment} from 'react';
import {connect} from 'react-redux';

import {Slider} from 'react-burgers'

import {setEnableMobileMenu, setEnableMobileMenuSmall,} from '../../redux/reducers/ThemeOptions';
import UserBox from "../AppHeader/UserBox";

class AppMobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false
        };

    }

    toggleMobileSidebar = () => {
        let {enableMobileMenu, setEnableMobileMenu} = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    }

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    render() {
        return (
            <Fragment>

                <div className="app-header__mobile-menu">
                    <div onClick={this.toggleMobileSidebar}>
                    <Slider 
                         width={26}
                         lineHeight={2}
                         lineSpacing={5}
                         color='#6c757d'
                    />
                    </div>
                </div>
                <div className="app-header__menu">
                    <UserBox/>
                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = state => ({
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({

    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AppMobileMenu);