import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {hideModalPopup} from "../../redux/actions/modalPopup.actions";
import "../../assets/css/components/modal.scss"

export const ModalPopup = (props) => {
	const modalPopup = useSelector(state => state.modalPopup)

	const dispatch = useDispatch()

	const onClose = () => {
		if (modalPopup.onCloseCallback) {
			modalPopup.onCloseCallback()
		}
		dispatch(hideModalPopup())
	}
	const onSubmitCallback = () => {
		modalPopup.onSubmitCallback()
		dispatch(hideModalPopup())
	}

	return (
		<Modal size={modalPopup.size} show={modalPopup.show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					{modalPopup.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					modalPopup.body
				}
			</Modal.Body>
			{
				modalPopup.onSubmitCallback
					? <Modal.Footer>
						<Button color="link" onClick={onClose}>Inchide</Button>
						<Button
							color={(modalPopup.type === 'delete' ? "danger" : "primary")}
							onClick={onSubmitCallback}>
							{
								modalPopup.actionText
									? modalPopup.actionText
									: modalPopup.type === 'delete' ? 'Sterge' : 'Salveaza'
							}
						</Button>
					</Modal.Footer>
					: null
			}
		</Modal>
	)
}
export default ModalPopup