import {isCleany} from "../../utils/app";

export const getMainNav = () => {
  let nav = [
    {
      icon: "pe-7s-graph2",
      label: "Statistici generale",
      to: "/dashboard",
    },
  ];

  if (!isCleany()) {
    nav.unshift(
        {
          icon: "pe-7s-graph",
          label: "Dashboard",
          to: "/dashboard-simple",
        },
    )
  }

  return nav
}

const getNeighbourhoodNav = () => {
  return {
    icon: "pe-7s-map",
    label: "Cartiere",
    content: [
      {
        label: "Cartiere",
        to: "/neighbourhoods",
      },
      {
        label: "Adrese publice",
        to: "/public-addresses",
      },
      {
        label: "Adauga adresa publica",
        to: "/add-public-address",
      },
      {
        label: "Utilizatori si agenti",
        to: "/map",
      },
      // {
      //   label: "Clienti posibili - Zumzi ",
      //   to: "/possible-clients",
      // },
    ]
  }
}

export const getResourceNav = () => {
  let nav = [
    {
      icon: "pe-7s-users",
      label: "Utilizatori",
      to: "/users",
    },
    {
      icon: "pe-7s-cart",
      label: "Comenzi",
      content: [
        {
          label: "Lista comenzi",
          to: "/orders",
        },
        {
          label: "Adauga comanda",
          to: isCleany() ? "/orders-add" : "/orders-add-zumzi",
        },
        {
          label: "Rambursari",
          to: "/refunds",
        },
      ],
    },
    {
      icon: "pe-7s-date",
      label: "Programari",
      to: "/bookings",
    },
    {
      icon: "pe-7s-repeat",
      label: "Abonamente",
      to: "/subscriptions",
    },
    {
      icon: "pe-7s-ticket",
      label: "Reduceri",
      content: [
        {
          label: "Lista Reduceri",
          to: "/offers",
        },
        {
          label: "Adaugare Reducere",
          to: "/offer-add",
        },
      ],
    },
    {
      icon: "pe-7s-stopwatch",
      label: "Zile blocate",
      to: "/blocked-dates",
    },
    {
      icon: "pe-7s-bell",
      label: "Notificări",
      content: [
        {
          label: "Notificari",
          to: "/notifications",
        },
        {
          label: "Trimite Notificare",
          to: "/add-push-notifications",
        },
      ],
    },
    {
      icon: "pe-7s-mail-open",
      label: "Formulare",
      content: [
        {
          label: "Comanda",
          to: "/product-forms",
        },
        {
          label: "Contact",
          to: "/tickets",
        },
      ],
    },
  ]
  if (!isCleany()) {
    nav.push(getNeighbourhoodNav())
  }
  return nav
}

export const MerchantNav = [
  {
    icon: "pe-7s-user",
    label: "Vanzatori",
    content: [
      {
        label: "Lista Vanzatori",
        to: "/merchants",
      },
      {
        label: "Adauga Vanzator",
        to: "/merchants/details",
      },
    ],
  },
  {
    icon: "pe-7s-portfolio",
    label: "Toate serviciile",
    to: "/products",
  },
]

export const AgentNav = [
  {
    icon: "pe-7s-user",
    label: "Agenti",
    content: [
      {
        label: "Lista Agenti",
        to: "/agents",
      },
      {
        label: "Incasari",
        to: "/stats-agents",
      },
    ],
  },
  {
    icon: "pe-7s-paper-plane",
    label: "Cereri",
    content: [
      {
        label: "Sedinte",
        to: "/booking-requests",
      },
      {
        label: "Formulare de Comanda",
        to: "/product-form-requests",
      },
    ],
  },
  {
    icon: "pe-7s-star",
    label: "Recenzii",
    to: "/reviews",
  },
];
