import {SET_MODAL_POPUP} from "../constants";
import {modalPopupInitialState} from "../../utils/model/modalPopup";

export const showModalPopup = (title, body, onSubmitCallback = null, size = 'md', onCloseCallback = null, type = '', actionText = '') => dispatch => {
	const payload = {
		show: true,
		title,
		body,
		size,
		onSubmitCallback,
		onCloseCallback,
		type,
		actionText
	}
	dispatch({type: SET_MODAL_POPUP, payload})
}
export const hideModalPopup = () => dispatch => {
	const payload = modalPopupInitialState

	dispatch({type: SET_MODAL_POPUP, payload})
}