import React, {Fragment, Component} from "react";

import Loader from 'react-loaders'

export default class LoaderComponent extends Component {
    render() {
        return <Fragment>
            <div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="line-scale-pulse-out"/>
                    </div>
                    <h6 className="mt-3">
                        Va rugam sa asteptati...
                    </h6>
                </div>
            </div>
        </Fragment>
    }
}