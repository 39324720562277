import { GET_PRODUCT_FORM_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = {}

export const productForm = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_FORM_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}