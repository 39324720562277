export const modalPopupInitialState = {
	show: false,
	title: '',
	body: '',
	onSubmitCallback: null,
	size: 'md',
	onCloseCallback: null,
	type: '',
	actionText: '',
}
