import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import {BrowserRouter} from 'react-router-dom';

import "./assets/css/input.scss";
import Main from './layout/';
import {Provider} from 'react-redux';
import {loadFromLocalStorage} from "./utils/storage";
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import reducers from "./redux/reducers";
import {isCleany} from "./utils/app";
import LoaderComponent from "./components/Common/Loader";
import {Spinner} from "./components/Common/Spinner";
import {reauthAction} from "./redux/actions";

if (isCleany()) {
	import("./assets/themes/_layout-cleany.scss").then(() => {
		renderApp(Main);
	})
} else {
	import("./assets/themes/_layout-zumzi.scss").then(() => {
		renderApp(Main);
	})
}

const store = createStore(
	combineReducers({
		...reducers
	}),
	applyMiddleware(thunk)
);

const authSession = loadFromLocalStorage('authSession');
if (authSession) {
	store.dispatch(reauthAction(authSession))
}

const rootElement = document.getElementById('root');
const renderApp = Component => {
	ReactDOM.render(
		<Provider store={store}>
			<BrowserRouter>
				<Spinner/>
				<Component fallback={<LoaderComponent/>}/>
			</BrowserRouter>
		</Provider>,
		rootElement
	);
};


if (module.hot) {
	module.hot.accept('./layout', () => {
		const NextApp = require('./layout').default;
		renderApp(NextApp);
	});
}
serviceWorker.unregister();