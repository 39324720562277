export const LOGOUT_RESET = 'LOGOUT_RESET'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

// alert modal
export const SHOW_ALERT_MODAL = 'SHOW_ALERT_MODAL'
export const HIDE_ALERT_MODAL = 'HIDE_ALERT_MODAL'

// spinner
export const SHOW_SPINNER = 'SHOW_SPINNER'
export const HIDE_SPINNER = 'HIDE_SPINNER'

// product form requests
export const GET_PRODUCT_FORM_REQUESTS_SUCCESS = 'GET_PRODUCT_FORM_REQUESTS_SUCCESS'

// product form request
export const GET_PRODUCT_FORM_REQUEST_SUCCESS = 'GET_PRODUCT_FORM_REQUEST_SUCCESS'

// product form details
export const SET_PRODUCT_FORM_DETAILS = 'SET_PRODUCT_FORM_DETAILS'

// product form
export const GET_PRODUCT_FORM_SUCCESS = 'GET_PRODUCT_FORM_SUCCESS'

// modal popup
export const SET_MODAL_POPUP = 'SET_MODAL_POPUP'

//  product option tree node
export const GET_PRODUCT_TREE_NODE_SUCCESS = 'GET_PRODUCT_TREE_NODE_SUCCESS'
