import axiosRequest from "../../api/axiosRequest";
import {HIDE_SPINNER, LOGIN_SUCCESS, SHOW_ALERT_MODAL, SHOW_SPINNER} from "../constants";
import {showDefaultErrorAction} from "./alertModal.actions";
import {saveToLocalStorage} from "../../utils/storage";
import {batch} from 'react-redux'

export const loginAction = (username, password) => async dispatch => {
    dispatch({type: SHOW_SPINNER})
    try {
        const resp = await axiosRequest.post('/login', {username, password})

        const data = resp.data
        const payload = {
            ...data,
            token: resp.headers['x-api-key']
        }
        batch(() => {
            dispatch({type: LOGIN_SUCCESS, payload})
            dispatch({type: HIDE_SPINNER})
            dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Bine ai revenit in contul tau'}})
        })
        await saveToLocalStorage('authSession', payload, 60 * 24 * 365)
    } catch (err) {
        dispatch({type: HIDE_SPINNER})
        if (err?.message?.includes('401')) {
            dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'error', message: 'Emailul sau parola sunt incorecte'}})
        } else {
            dispatch(showDefaultErrorAction(err))
        }
    }
}

export const reauthAction = payload => async dispatch => {
    dispatch({type: LOGIN_SUCCESS, payload})
}
