import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { Redirect, withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";
import Routes from "./Routes";
import { saveToLocalStorage } from "../utils/storage";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
      currentPath: null,
    };
  }

  componentDidMount() {
    if (!this.props.authSession && this.props.location.pathname !== "/") {
      saveToLocalStorage("previousLocation", `${this.props.location.pathname}${this.props.location.search}`);
    }
  }

  componentDidUpdate() {
    if (window.location.pathname !== this.state.currentPath) {
      this.setState({ currentPath: window.location.pathname });
    }
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    if (!this.props.authSession && this.state.currentPath !== "/") {
      return <Redirect to="/" />;
    }

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}
            >
              {this.props.authSession ? (
                <Fragment>
                  <AppHeader />
                  <div className="app-main">
                    <AppSidebar />
                    <div className="app-main__outer">
                      <div className="app-main__inner">
                        <Routes />
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Routes />
              )}
            </div>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  authSession: state.authSession,
});

export default withRouter(connect(mapStateToProp)(Main));
