import {HIDE_SPINNER, SHOW_ALERT_MODAL} from '../constants'
import {batch} from "react-redux";
import {getErrorMessage} from "../../utils/errorMessage";

export const showAlertModalAction = (type, message, stack = null, screen = null, params = {}) => dispatch => {
  const payload = {
    isVisible: true,
    type,
    message,
    stack,
    screen,
    params
  }
  dispatch({ type: SHOW_ALERT_MODAL, payload })
}

export const showDefaultErrorAction = (err) => async dispatch => {
  batch(() => {
    dispatch({ type: HIDE_SPINNER })
    dispatch({ type: SHOW_ALERT_MODAL, payload: { type: 'error', message: getErrorMessage(err) } })
  })
}